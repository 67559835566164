class ZIDXTermsOfUse implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxTermsOfUseContainer";
    }
    getMatchURL(){
        return "/terms-of-use/";
    }
    render(){
        // console.log("terms of use");
    }
}
